import Vue from 'vue'
import App from './App.vue'
import Vuex from 'vuex'
import { IubendaStore } from 'mars-iubenda-vite'

Vue.use(Vuex);
const vuexStore = new Vuex.Store({
  modules: {
    iubenda: IubendaStore,
  }
});

Vue.config.productionTip = false

const app = new Vue({
  delimiters: ["[[", "]]"],
  store: vuexStore,
  render: h => h(App)
})
.$mount('#cookie-vue')

console.log("Iubenda Vue main.ts", app)