<template>
  <div class="footer-vue">
    <div style="font-size: 12px">
      <div>Puntoquindici srl - VAT ID: 04509530236</div>
      <div>&copy; Copyright 2024. All rights reserved.</div>
    </div>
    <div class="footer-social">
        <div class="flex">
          <a href="https://www.linkedin.com/showcase/myarstudio/" target="_blank">
            <img loading="lazy" class="mr-2 xl:mx-2" src="https://www.myarstudio.cloud/assets/img/linkedin.0516703.svg" alt="Linkedin">
          </a>
        </div>
        <div class="flex">
          <a href="https://www.instagram.com/my.ar.studio/" target="_blank">
            <img loading="lazy" class="mr-2 xl:mx-2" src="https://www.myarstudio.cloud/assets/img/instagram.4137e99.svg" alt="Instagram">
          </a>
        </div>
        <div class="flex">
          <a href="https://www.youtube.com/@myarstudio" target="_blank">
            <img loading="lazy" class="mr-2 xl:mx-2" src="https://www.myarstudio.cloud/assets/img/ico-youtube.5f2eb0c.svg" alt="YouTube">
          </a>
        </div>
      </div>
    <div style="display: flex; flex-direction: column; font-size: 14px; gap: 8px">
      <a :href="contactsLink" target="_blank">CONTACT US</a>
      <a href="https://www.iubenda.com/privacy-policy/20018456" target="_blank">PRIVACY POLICY / IMPRESSUM</a>
      <a class="iubenda-cs-preferences-link">COOKIE PREFERENCES</a>
    </div>
  </div>
</template>
<script>
import Vue from 'vue';
import { IubendaMixin, installIubenda } from 'mars-iubenda-vite'

export default Vue.extend({
  name: 'App',
  mixins: [IubendaMixin],
  components: {
  },
  computed: {
    contactsLink() {
      return this.locale == 'it' ? "https://www.myarstudio.cloud/it/contattaci" : "https://www.myarstudio.cloud/contacts"
    },
    locale() {
      return  document.location.pathname.indexOf('/it/') != -1 ? 'it' : 'en';
    }
  },
  created() {
    console.log("Iubenda Vue App created");
    installIubenda(this.locale, true, this.$store);
    window.vue = {
      youtubeEnabled: () => this.youtubeEnabled,
      clickupFormsEnabled: () => this.clickupFormsEnabled
    };
    console.log("Iubenda Vue App created end");
  }
});
</script>

<style scoped>
  .footer-vue {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  .footer-social {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 1rem;
  }
  @media (max-width: 672px) {
    .footer-vue {
      flex-direction: column-reverse;
      align-items: start;
    }
    .footer-social {
      justify-content: start;
    }
  }
</style>
