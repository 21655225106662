import m from "vue";
const w = {
  namespaced: !0,
  state() {
    return {
      log: !0,
      // if true it logs on the console (debug)
      preference_not_needed: !1,
      consent_given: !1,
      consent_rejected: !1,
      consent_given_purpose: {
        1: !1,
        2: !1,
        3: !1,
        4: !1,
        5: !1
      },
      ccpa_opted_out: !1
    };
  },
  actions: {
    setShouldLog(t, e) {
      t.commit("SET_SHOULD_LOG", e);
    },
    setPreferenceNotNeeded(t, e) {
      t.commit("SET_PREFERENCE_NOT_NEEDED", e);
    },
    setConsetGiven(t, e) {
      t.commit("SET_PREFERENCE_NOT_NEEDED", e);
    },
    setConsetRejected(t, e) {
      t.commit("SET_CONSENT_REJECTED", e);
    },
    setConsetGivenPurpose(t, { iPurpose: e, bValue: n }) {
      t.commit("SET_CONSENT_GIVEN_PURPOSE", { iPurpose: e, bValue: n });
    },
    resetConsetGivenPurpose(t) {
      for (const e of [1, 2, 3, 4, 5])
        t.commit("SET_CONSENT_GIVEN_PURPOSE", { iPurpose: e, bValue: !1 });
    },
    setCcpaOptedOut(t, e) {
      t.commit("SET_CCPA_OPTED_OUT", e);
    }
  },
  mutations: {
    SET_SHOULD_LOG(t, e) {
      t.log = e;
    },
    SET_PREFERENCE_NOT_NEEDED(t, e) {
      t.preference_not_needed = e;
    },
    SET_CONSENT_GIVEN(t, e) {
      t.consent_given = e;
    },
    SET_CONSENT_REJECTED(t, e) {
      t.consent_rejected = e;
    },
    SET_CONSENT_GIVEN_PURPOSE(t, { iPurpose: e, bValue: n }) {
      t.consent_given_purpose[e] = n;
    },
    SET_CCPA_OPTED_OUT(t, e) {
      t.ccpa_opted_out = e;
    }
  },
  getters: {
    log(t) {
      return t.log;
    },
    consentGivenForPurpose1(t) {
      return g(t, 1);
    },
    consentGivenForPurpose2(t) {
      return g(t, 2);
    },
    consentGivenForPurpose3(t) {
      return g(t, 3);
    },
    consentGivenForPurpose4(t) {
      return g(t, 4);
    },
    consentGivenForPurpose5(t) {
      return g(t, 5);
    },
    necessaryEnabled(t, e) {
      return e.consentGivenForPurpose1;
    },
    functionalityEnabled(t, e) {
      return e.consentGivenForPurpose2;
    },
    experienceEnabled(t, e) {
      return e.consentGivenForPurpose3;
    },
    measurementEnabled(t, e) {
      return e.consentGivenForPurpose4;
    },
    marketingEnabled(t, e) {
      return e.consentGivenForPurpose5;
    },
    clickupFormsEnabled(t, e) {
      return e.consentGivenForPurpose2;
    },
    youtubeEnabled(t, e) {
      return e.consentGivenForPurpose3;
    },
    mauticEnabled(t, e) {
      return e.consentGivenForPurpose4;
    },
    livechatEnabled(t, e) {
      return e.consentGivenForPurpose2;
    }
  }
};
function g(t, e) {
  return !t.consent_rejected && (t.preference_not_needed || t.consent_given || t.consent_given_purpose[e]);
}
const C = m.extend({
  // TODO: could not make typescript get the $store object in vue: casting this to any for $store calls...
  computed: {
    iubendaShouldLog() {
      return this.$store.getters["iubenda/log"];
    },
    clickupFormsEnabled() {
      return this.$store.getters["iubenda/clickupFormsEnabled"];
    },
    mauticEnabled() {
      return this.$store.getters["iubenda/mauticEnabled"];
    },
    youtubeEnabled() {
      return this.iubendaShouldLog && console.log("youtubeEnabled", this.$store.getters["iubenda/youtubeEnabled"]), this.$store.getters["iubenda/youtubeEnabled"];
    },
    livechatEnabled() {
      return this.$store.getters["iubenda/livechatEnabled"];
    },
    consentGivenForPurpose1() {
      return this.$store.getters["iubenda/consentGivenForPurpose1"];
    },
    consentGivenForPurpose2() {
      return this.$store.getters["iubenda/consentGivenForPurpose2"];
    },
    consentGivenForPurpose3() {
      return this.$store.getters["iubenda/consentGivenForPurpose3"];
    },
    consentGivenForPurpose4() {
      return this.$store.getters["iubenda/consentGivenForPurpose4"];
    },
    consentGivenForPurpose5() {
      return this.$store.getters["iubenda/consentGivenForPurpose5"];
    },
    necessaryEnabled() {
      return this.$store.getters["iubenda/necessaryEnabled"];
    },
    // level 1
    functionalityEnabled() {
      return this.$store.getters["iubenda/functionalityEnabled"];
    },
    // level 2
    experienceEnabled() {
      return this.$store.getters["iubenda/experienceEnabled"];
    },
    // level 3
    measurementEnabled() {
      return this.$store.getters["iubenda/measurementEnabled"];
    },
    // level 4
    marketingEnabled() {
      return this.$store.getters["iubenda/marketingEnabled"];
    }
    // level 5
  }
}), f = function(t) {
  var e;
  return (e = document.cookie.split("; ").find((n) => n.startsWith(t + "="))) == null ? void 0 : e.split("=")[1];
}, v = function(t, e) {
  switch (console.log("Iubenda enable level", t), t) {
    case 1:
      console.log("Iubenda -> GTag"), function(n, u, a, r, o) {
        n[r] = n[r] || [], n[r].push({ "gtm.start": (/* @__PURE__ */ new Date()).getTime(), event: "gtm.js" });
        const s = u.getElementsByTagName(a)[0], l = u.createElement(a), i = r !== "dataLayer" ? "&l=" + r : "";
        l.async = !0, l.src = "https://www.googletagmanager.com/gtm.js?id=" + o + i, s.parentNode.insertBefore(l, s);
      }(window, document, "script", "dataLayer", "GTM-K9HP22W"), window.iub.level1 = !0;
      break;
    case 2:
      console.log("Iubenda -> LiveChat"), window.__lc = window.__lc || {}, window.__lc.license = 12709530, function(n, u, a) {
        function r(s) {
          return o._h ? o._h.apply(null, s) : o._q.push(s);
        }
        var o = { _q: [], _h: null, _v: "2.0", on: function() {
          r(["on", a.call(arguments)]);
        }, once: function() {
          r(["once", a.call(arguments)]);
        }, off: function() {
          r(["off", a.call(arguments)]);
        }, get: function() {
          if (!o._h)
            throw new Error("[LiveChatWidget] You can't use getters before load.");
          return r(["get", a.call(arguments)]);
        }, call: function() {
          r(["call", a.call(arguments)]);
        }, init: function() {
          var s = u.createElement("script");
          s.async = !0, s.type = "text/javascript", s.src = "https://cdn.livechatinc.com/tracking.js", u.head.appendChild(s);
        } };
        !n.__lc.asyncInit && o.init(), n.LiveChatWidget = n.LiveChatWidget || o;
      }(window, document, [].slice), window.iub.level2 = !0;
      break;
    case 3:
      window.iub.level3 = !0;
      break;
    case 4:
      window.iub.level4 = !0;
      break;
    case 5:
      console.log("Iubenda -> Mautic"), function(n, u, a, r, o, s, l) {
        n.MauticTrackingObject = o, n[o] = n[o] || function() {
          (n[o].q = n[o].q || []).push(arguments);
        }, s = u.createElement(a), l = u.getElementsByTagName(a)[0], s.async = 1, s.src = r, l.parentNode.insertBefore(s, l);
      }(window, document, "script", "https://ma.myarstudio.cloud/media/js/mtc.js", "mt"), e && e.user_id ? window.mt("send", "pageview", { panel_user_id: e.user_id }) : window.mt("send", "pageview"), window.iub.level5 = !0;
      break;
  }
}, F = function(t, e = !0, n = void 0, u = "https://www.myarstudio.cloud") {
  console.log("installIubenda9", t, e), window.iub = {
    level1: !1,
    level2: !1,
    level3: !1,
    level4: !1,
    level5: !1
  };
  const a = 2701995, r = {
    en: 20018456,
    it: 61469158,
    de: 21462515
  }, o = r[t] || r.en, s = {
    acceptButtonCaptionColor: "#FFFFFF",
    acceptButtonColor: "#0073CE",
    acceptButtonDisplay: !0,
    backgroundColor: "#FFFFFF",
    backgroundOverlay: !1,
    brandBackgroundColor: "#FFFFFF",
    brandTextColor: "#000000",
    closeButtonDisplay: !1,
    customizeButtonCaptionColor: "#4D4D4D",
    customizeButtonColor: "#DADADA",
    customizeButtonDisplay: !0,
    explicitWithdrawal: !0,
    listPurposes: !0,
    logo: "https://www.myarstudio.cloud/assets/img/logo.d9ac90d.svg",
    position: "float-bottom-center",
    rejectButtonCaptionColor: "#FFFFFF",
    rejectButtonColor: "#0073CE",
    rejectButtonDisplay: !0,
    showPurposesToggles: !0,
    textColor: "#000000"
  };
  n && n.dispatch("iubenda/setShouldLog", e), console.log("shallInstall", !0);
  {
    const i = window.dataLayer || null;
    e && console.log(
      "App.vue installIubenda language: ",
      t,
      "siteId:",
      a,
      "cookiePolicyId:",
      o
    ), window.__iubCategories = {}, window._iub = window._iub || {}, window._iub.csConfiguration = {
      askConsentAtCookiePolicyUpdate: !0,
      countryDetection: !0,
      applyGdprForCH: !1,
      enableFadp: !0,
      enableLgpd: !0,
      enableUspr: !0,
      usprPurposes: "s,adv",
      gdprAppliesGlobally: !1,
      lgpdAppliesGlobally: !1,
      lang: t,
      localConsentDomain: "myarstudio.cloud",
      logLevel: "debug",
      perPurposeConsent: !0,
      siteId: a,
      whitelabel: !1,
      cookiePolicyId: o,
      reloadOnConsent: !0,
      banner: s,
      // https://www.iubenda.com/en/help/74198-google-consent-mode-set-up-google-tag-manager-with-iubenda
      // see "Manual embedding"
      googleConsentMode: "template",
      emitGtmEvents: !0,
      callback: {
        onPreferenceExpressedOrNotNeeded: (c) => {
          window.__iubCategories = window._iub.cs.consent.purposes, e && console.log("Iubenda cb ");
          const p = window._iub.cs.api.isCcpaOptedOut();
          if (i && i.push({
            iubenda_ccpa_opted_out: p
          }), n && n.dispatch("iubenda/setCcpaOptedOut", p), e && console.log("Iubenda cb: GTag dataLayer push ", "iubenda_ccpa_opted_out:" + p), !c)
            i && i.push({
              event: "iubenda_preference_not_needed"
            }), n && n.dispatch("iubenda/setPreferenceNotNeeded", !0), e && console.log("Iubenda cb: GTag dataLayer push ", "iubenda_preference_not_needed");
          else if (n && n.dispatch("iubenda/setPreferenceNotNeeded", !1), c.consent === !0)
            i && i.push({
              event: "iubenda_consent_given"
            }), n && n.dispatch("iubenda/setConsetGiven", !0), e && console.log("Iubenda cb: GTag dataLayer push ", "iubenda_consent_given");
          else if (c.consent === !1)
            i && i.push({
              event: "iubenda_consent_rejected"
            }), n && n.dispatch("iubenda/setConsetRejected", !0), e && console.log("Iubenda cb: GTag dataLayer push ", "iubenda_consent_rejected");
          else if (c.purposes) {
            n && n.dispatch("iubenda/resetConsetGivenPurpose");
            for (const _ in c.purposes)
              if (c.purposes[_]) {
                i && i.push({
                  event: "iubenda_consent_given_purpose_" + _
                }), n && n.dispatch("iubenda/setConsetGivenPurpose", { iPurpose: _, bValue: !0 }), e && console.log("Iubenda cb: GTag dataLayer push ", "iubenda_consent_given_purpose_" + _);
                const b = f("mars_user_info");
                console.log("mars cookie user", b);
                const d = b && JSON.parse(b);
                d && d.user_id && d.team_id && d.user_plan && (window.dataLayer = window.dataLayer || [], window.dataLayer.push({
                  user_id: d.user_id,
                  team_id: d.team_id,
                  user_plan: d.user_plan
                })), v(+_, d);
              }
          }
        }
      }
    };
    const E = [
      { src: "//cs.iubenda.com/sync/2701995.js" },
      { src: "//cdn.iubenda.com/cs/gpp/stub.js" },
      // local modified version of
      // { src: '//cdn.iubenda.com/cs/iubenda_cs.js', charset: 'UTF-8', async: '' },
      // NOTE: this downloads a versioned script. It probably must be updated regurarily.
      // There is one file per language: they have been modified to encapsulate the iubenda elements
      // with a <section> element to avoid double H1 in page
      { src: `${u}/js/iubenda/iubenda_cs.js`, charset: "UTF-8", async: "" }
      // integrated into this script: no longer needed the external bootstrap
      // { src: `${baseUrl}/js/iubendaBootstrap.js`, charset: 'UTF-8', async: '' },
      // old setup:
      // { src: '/js/debugIubendaBootstrap.js' },
      // { src: '//cdn.iubenda.com/cs/ccpa/stub.js' },
      // { src: '//cdn.iubenda.com/cs/iubenda_cs.js', charset: 'UTF-8', async: '' },
    ];
    for (const c of E) {
      e && console.log("Appending script tag", c);
      const p = document.createElement("script");
      Object.keys(c).forEach((b) => {
        p.setAttribute(b, c[b]);
      }), document.head.appendChild(p);
    }
  }
};
export {
  C as IubendaMixin,
  w as IubendaStore,
  F as installIubenda
};
